import { AbstractModel } from "@gipi-pages/abstract/models/abstract.model";
import { GIPIUuid } from "@gipi-shared/types/uuid.type";
import { UUIDUtil } from "@gipi-ui/utils/uuid.util";
import { ClientStatus } from "../enums/client-status.enum";
import { PersonModel } from "./person.model";
import { TerminalModel } from "./terminal.model";

export class ClientModel extends AbstractModel {

    person: PersonModel;

    quantityNfDenied: number;

    dateTimeLastBackup: Date;

    dateBlockingContingency: Date;

    dateTimeLastAccess: Date;

    blocked: boolean;

    terminals: TerminalModel[];

    updateStatus: ClientStatus;

    hasFinancial: boolean;

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
