import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DelayBlockingListDialogComponent } from '@gipi-pages/delay-blocking/components/delay-blocking-list-dialog/delay-blocking-list-dialog.component';
import { LozengeType } from '@gipi-ui/components/lozenge/lozenge.component';
import { TypeAuthorities } from '@gipi-ui/enums/enum-authorities.enum';
import { Library } from '@gipi-ui/global/library';
import { ValidateAccessService } from '@gipi-ui/services/validate-access.service';
import { ArrayUtil } from '@gipi-ui/utils/array.util';
import { DateUtil } from '@gipi-ui/utils/date.util';
import { ObjectUtil } from '@gipi-ui/utils/object.util';
import { StringUtil } from '@gipi-ui/utils/string.util';
import { COLOR, SimpleStatusCell } from 'src/app/components/global/simple-status-cell/model/simple-status-cell.model';
import { SnackbarService } from 'src/app/components/global/snackbar/services/snackbar.service';
import { DialogConfirmComponent } from 'src/app/components/shared/dialog-confirm/dialog-confirm.component';
import { TYPE_BUTTONS, TYPE_DIALOG } from 'src/app/components/shared/dialog-confirm/models/dialog-confirm.model';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { HttpClientBase } from 'src/app/core/request/httpClientBase';
import { EnumUpdateStatus } from 'src/app/shared/enums/enum-update-status.enum';
import { SearchInformationModel } from 'src/app/shared/models/search-information.model';
import { TaskCheckbox } from 'src/app/shared/models/task-checkbox.model';
import { TaskRadiobutton } from 'src/app/shared/models/task-radiobutton.model';
import { ExcelService, HeaderExcel } from 'src/app/shared/services/excel.service';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { ClientExcelModelDTO } from '../../models/client-excel.model.dto';
import { ClientModel } from '../../models/client.model';
import { PersonModel } from '../../models/person.model';
import { TerminalAppModel } from '../../models/terminal-app.model';
import { TerminalModel } from '../../models/terminal.model';
import { TerminalsService } from '../../services/terminals.service';
import { UpdateClientService } from '../../services/update-client.service';
import { ClientContingencyBlockedModel, DialogContingencyBlockedComponent } from '../dialog-contingency-blocked/dialog-contingency-blocked.component';
import { ContingencyBlockedModel } from '../dialog-contingency-blocked/models/contingency-blocked.model';
import { DelayBlockingService } from '../dialog-delay-blocking/services/delay-blocking.service';
import { DialogLicenseGeneratorComponent } from '../dialog-license-generator/dialog-license-generator.component';

@Component({
    selector: 'gipi-table-clients',
    templateUrl: './table-clients.component.html',
    styleUrls: ['./table-clients.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class TableClientsComponent implements AfterViewInit {

    private _taskCheckbox: TaskCheckbox;

    private _taskRadioButtonMigrated: TaskRadiobutton;

    private _taskRadioButtonBlocked: TaskRadiobutton;

    private _dataSourceInitial: ClientModel[] = [];

    private _inputFilter: string = '';

    private _searchInformationModel: SearchInformationModel = {
        pageIndex: -1,
        filter: '',
        taskCheckbox: {},
        taskRadioButton: {}
    };

    public dataSource: MatTableDataSource<ClientModel>;

    public columnsToDisplay: string[] = ['isFinancial', 'select', 'corporateName', 'name', 'cpfOrCnpj', 'blocked', 'dateBlockingContingency', 'dateTimeLastAccess', 'status', 'actions'];

    public expandedElement: ClientModel | null;

    public expand: boolean = true;

    public selection: SelectionModel<ClientModel> = new SelectionModel<ClientModel>(true, []);

    public selectedCount: number = 0;

    public contingencyBlocked: boolean = false;

    public isLoading: boolean = false;

    public cpfOrCnpjClient: string = '';

    public containsElements: boolean = false;

    public hoverbutton: number;

    public isValidAccess: boolean = false;

    public terminalList: TerminalModel[] = [];

    public loadingTerminal: boolean = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Output() outputSelectedCount: EventEmitter<number> = new EventEmitter<number>();

    @Output() outputLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _changeDetectorRefs: ChangeDetectorRef,
        private _snackbarService: SnackbarService,
        private _dialogService: MatDialog,
        private _authenticationService: AuthenticationService,
        private _validateAccessService: ValidateAccessService,
        private _excelService: ExcelService,
        private _updateClientService: UpdateClientService,
        private _terminalsService: TerminalsService,
        private _delayBlockingService: DelayBlockingService,
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isValidAccess = (this.validateAccess('ROLE_SUPPORT') || this.validateAccess('ROLE_DEVELOPERS'));

            this.findClients(false, false).then((result) => {
                if (result) {
                    this.containsElements = (this.dataSource && this.dataSource.data ? this.dataSource.data.length > 0 : false);
                } else {
                    this.containsElements = false;
                }
            });

            EventEmitterService.get('EventRadiobuttonObjectClientsMigrated').subscribe((data) => {
                this._taskRadioButtonMigrated = data;
            });

            EventEmitterService.get('EventRadiobuttonObjectClientsBlocked').subscribe((data) => {
                this._taskRadioButtonBlocked = data;
            });

            EventEmitterService.get('EventInputValueClients').subscribe((data) => {
                this._inputFilter = data;
                this.applyFilter(data);
            });

            EventEmitterService.get('EventCheckBoxObjectClients').subscribe((data) => {
                this._taskCheckbox = data;
            });

            EventEmitterService.get('EventInputValueCheckboxClients').subscribe((data) => {
                this.selectDesiredQuantity(data);
            });

            EventEmitterService.get('EventRefreshUpdateClients').subscribe((data) => {
                this.refreshClients(data);
            });

            // EventEmitterService.get('EventRefreshUpdateClientsOne').subscribe((data) => {
            //     this.refreshClients(false, data);
            // });

            EventEmitterService.get('EventRefreshTerminalClients').subscribe((data) => {
                if (data) {
                    this.findTerminalsClient();
                }
            });

            EventEmitterService.get('EventActionUpdateClients').subscribe((data) => {
                if (data) {
                    this.onClickActionClients(null, '', data);
                }
            });

            EventEmitterService.get('EventExportExcelClients').subscribe((data) => {
                if (data) {
                    this.onClickExportExcelClients();
                }
            });

            document.getElementById('inputFindClients').focus();
        });
    }

    private findClients(updateCurrentVersionApp: boolean = false, syncClients: boolean = false): Promise<boolean> {
        this.isLoading = true;
        this.outputLoading.emit(this.isLoading);
        this.cpfOrCnpjClient = null;

        return new Promise(async (resolve) => {
            await this._updateClientService.findByTypeEnvironment(HttpClientBase.typeEnvironment, -1, updateCurrentVersionApp, syncClients).then((listClientModel) => {
                if (this.dataSource) {
                    // Salva as informações de pesquisa na sessionStorage.
                    this.saveSearchInformation();
                }

                if (listClientModel !== null) {

                    this.dataSource = new MatTableDataSource(listClientModel);

                    if (listClientModel.length > 0) {
                        this._dataSourceInitial = listClientModel.slice();
                    }

                    this.recoverSearchInformation();
                    if (!ObjectUtil.isNull(this._searchInformationModel)) {
                        if (this._searchInformationModel.pageIndex >= 0) {
                            this.paginator.pageIndex = this._searchInformationModel.pageIndex;
                        } else {
                            this.paginator.firstPage();
                        }
                    }

                    this.dataSource.paginator = this.paginator;

                    this.dataSource.filterPredicate = (
                        clientModel: ClientModel,
                        filter: string
                    ): boolean => {
                        return this.filterClients(clientModel, filter)
                    };

                }

                this.isLoading = false;
                this.outputLoading.emit(this.isLoading);
                resolve(true);

            }, (error) => {
                this.isLoading = false;
                this.outputLoading.emit(this.isLoading);
                resolve(false);
            });
        });
    }

    private saveSearchInformation(): void {
        this._searchInformationModel.pageIndex = this.dataSource.paginator.pageIndex;
        this._searchInformationModel.filter = this._inputFilter;
        this._searchInformationModel.taskCheckbox = this._taskCheckbox;
        this._searchInformationModel.taskRadioButton = [this._taskRadioButtonMigrated, this._taskRadioButtonBlocked];

        const filter: {
            pageIndex: number;
            filter: string;
            taskCheckbox: TaskCheckbox;
            taskRadioButtonMigrated: TaskRadiobutton;
            taskRadioButtonBlocked: TaskRadiobutton;
        } = {
            pageIndex: this.dataSource.paginator.pageIndex,
            filter: this._inputFilter,
            taskCheckbox: this._taskCheckbox,
            taskRadioButtonMigrated: this._taskRadioButtonMigrated,
            taskRadioButtonBlocked: this._taskRadioButtonBlocked,
        }

        sessionStorage.setItem('GIPIApoio.filter', JSON.stringify(filter));
    }

    private recoverSearchInformation(): void {
        const filter: string = sessionStorage.getItem('GIPIApoio.filter');
        if (!StringUtil.isEmpty(filter)) {
            const sessionStorageFilter: {
                pageIndex: number;
                filter: string;
                taskCheckbox: TaskCheckbox;
                taskRadioButton: TaskRadiobutton;
            } = JSON.parse(filter);

            this._searchInformationModel.pageIndex = sessionStorageFilter.pageIndex;
            this._searchInformationModel.filter = sessionStorageFilter.filter;
            this._searchInformationModel.taskCheckbox = sessionStorageFilter.taskCheckbox;
            this._searchInformationModel.taskRadioButton = sessionStorageFilter.taskRadioButton;
        }
    }

    private filterClients(clientModel: ClientModel, filter: string): boolean {
        // Se o input estiver vazio ele não chama o filter do datasource, por algum motivo não descoberto ainda.
        // Portando, ao passar o * ele chama o filter e assim procede como deveria, realizando os filtros mesmo
        // com o input vazio. ***** Se o motivo for descoberto, e a correção for mais prática, corrija. *****
        if (filter === '*') {
            return (
                this.checkVersionClient(clientModel.hasFinancial) &&
                this.checkUpdateStatus(clientModel.updateStatus.toLowerCase().trim()) &&
                this.checkBlockedClient(clientModel.blocked)
            );
        } else {
            let filterAux: string = filter;
            filterAux = filterAux.replace(/[^0-9]/g, '');

            return (
                this.checkVersionClient(clientModel.hasFinancial) &&
                this.checkUpdateStatus(clientModel.updateStatus.toLowerCase().trim()) &&
                this.checkBlockedClient(clientModel.blocked)
            ) && (
                    clientModel.id.toString().includes(filter) ||
                    clientModel.person.name.toLowerCase().includes(filter) ||
                    (clientModel.person.fantasyName !== null ? clientModel.person.fantasyName.toLowerCase().includes(filter) : false) ||
                    (clientModel.person.legalPerson !== null ? clientModel.person.legalPerson.cnpj.toLowerCase().includes((filterAux ? filterAux : filter)) : false) ||
                    (clientModel.person.naturalPerson !== null ? clientModel.person.naturalPerson.cpf.toLowerCase().includes((filterAux ? filterAux : filter)) : false) ||
                    clientModel.updateStatus.toString().includes(filter)
                );
        }

    }

    private checkUpdateStatus(updateStatus: string): boolean {
        for (const subtask in this._taskCheckbox.subtasks) {
            if (this._taskCheckbox.subtasks[subtask].nameStatus === updateStatus.toUpperCase().trim()) {
                return this._taskCheckbox.subtasks[subtask].completed;
            }
        }
    }

    private checkVersionClient(migrated: boolean): boolean {
        if ((this._taskRadioButtonMigrated.nameStatus === 'HAS_FINANCIAL') && (migrated)) {
            return this._taskRadioButtonMigrated.checked;
        } else if ((this._taskRadioButtonMigrated.nameStatus === 'NOT_HAS_FINANCIAL') && (!migrated)) {
            return this._taskRadioButtonMigrated.checked;
        } else if ((this._taskRadioButtonMigrated.nameStatus === 'ALL_CLIENTS')) {
            return this._taskRadioButtonMigrated.checked;
        }

        return false;
    }

    private checkBlockedClient(blocked: boolean): boolean {
        if ((this._taskRadioButtonBlocked.nameStatus === 'BLOCKED') && (blocked)) {
            return this._taskRadioButtonBlocked.checked;
        } else if ((this._taskRadioButtonBlocked.nameStatus === 'NOT_BLOCKED') && (!blocked)) {
            return this._taskRadioButtonBlocked.checked;
        } else if ((this._taskRadioButtonBlocked.nameStatus === 'ALL_CLIENTS')) {
            return this._taskRadioButtonBlocked.checked;
        }

        return false;
    }

    public applyFilter(valueInput: string): void {
        // Se realizar algum filtro vai limpar os checkbox selecionados,
        // para não correr o perigo de pegar algum valor sem querer
        this.selection.clear();

        // Se o input estiver vazio ele não chama o filter do datasource, por algum motivo não descoberto ainda.
        // Portando, ao passar o * ele chama o filter e assim procede como deveria, realizando os filtros mesmo
        // com o input vazio. ***** Se o motivo for descoberto, e a correção for mais prática, corrija. *****
        if (valueInput.trim() === '') {
            this.dataSource.filter = '*';
        } else {
            this.dataSource.filter = valueInput.trim().toLowerCase();
        }

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

        // Salva as informações da pesquisa.
        this.saveSearchInformation();
    }

    public async refreshClients(isRefreshAll: boolean = false, idClient: number = -1): Promise<void> {
        this.cpfOrCnpjClient = null;
        if (isRefreshAll) {
            if (this.dataSource) {
                this.dataSource.data = [];
            }

            this.isLoading = true;
            this.outputLoading.emit(this.isLoading);

            this.findClients(true, true).then((result) => {
                this.isLoading = false;
                this.outputLoading.emit(this.isLoading);
                this.applyFilter(this._inputFilter);
            });
        } else {
            // Se o id do cliente que é para atualizar estiver vazio,
            // chama essa função (refreshClients) fazendo com que atualize todos os clientes e já para a função.
            // Agora se o id do cliente estiver carregado vai atualizar apenas o cliente especifico.
            if (!idClient || idClient < 0) {
                this.refreshClients(true);
                return;
            }

            // Salva as informações de pesquisa na sessionStorage.
            this.saveSearchInformation();

            let lListClientModel: ClientModel[] = [];
            if (this.dataSource && this.dataSource.data) {
                lListClientModel = this.dataSource.data.slice()
                this.dataSource.data = [];
            }

            this.isLoading = true;
            this.outputLoading.emit(this.isLoading);

            this._updateClientService.findById(idClient).toPromise().then(clientModel => {
                if (lListClientModel.length > 0) {
                    const lIndex: number = lListClientModel.findIndex(cm => cm.id === idClient);
                    if (lIndex >= 0) {
                        lListClientModel.splice(lIndex, 1, clientModel);
                    }
                }

                if (this.dataSource) {
                    this.dataSource.data = lListClientModel.slice();
                }

                this.recoverSearchInformation();
                if (!ObjectUtil.isNull(this._searchInformationModel)) {
                    if (this._searchInformationModel.pageIndex >= 0) {
                        this.paginator.pageIndex = this._searchInformationModel.pageIndex;
                    } else {
                        this.paginator.firstPage();
                    }
                }

                this.dataSource.paginator = this.paginator;

                this.recoverExpandedClient(clientModel);

                this.isLoading = false;
                this.outputLoading.emit(this.isLoading);
                this._changeDetectorRefs.detectChanges();
            });
        }
    }

    public recoverExpandedClient(clientModel: ClientModel): void {
        this.expand = true;
        this.expandedElement = clientModel;

        // Carrega informações do terminal
        let lCpfOrCnpj: string = '';
        if (!ObjectUtil.isNull(clientModel.person)) {
            if (!ObjectUtil.isNull(clientModel.person.legalPerson) && !StringUtil.isEmpty(clientModel.person.legalPerson.cnpj)) {
                lCpfOrCnpj = clientModel.person.legalPerson.cnpj;
            } else if (!ObjectUtil.isNull(clientModel.person.naturalPerson) && !StringUtil.isEmpty(clientModel.person.naturalPerson.cpf)) {
                lCpfOrCnpj = clientModel.person.naturalPerson.cpf;
            }
        }
        this.cpfOrCnpjClient = lCpfOrCnpj; // Tem que ficar acima do this.findTerminalsClient();
        this.terminalList = [];
        this.terminalList = [...clientModel.terminals];
    }

    public validateAccess(authorities: TypeAuthorities): boolean {
        return this._validateAccessService.validateAccess(authorities);
    }

    public isAllSelected(): boolean {
        const numSelected: number = this.selection.selected.length;
        const numRows: number = this.dataSource.filteredData.length;
        return numSelected === numRows;
    }

    public masterToggle(): void {
        this.selectedCount = this.selection.selected.length;

        if (this.selectedCount > 0) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((dataRow) => {
                if (this.dataSource.filteredData.indexOf(dataRow) !== -1) {
                    this.selection.select(dataRow)
                }
            });
        }
    }

    public selectDesiredQuantity(selectCheckbox: number): void {
        this.selection.clear();

        if (selectCheckbox > this.dataSource.filteredData.length) {
            selectCheckbox = this.dataSource.filteredData.length;
        }

        if (selectCheckbox > 0) {
            for (let i: number = 0; i < selectCheckbox; i++) {
                this.selection.select(this.dataSource.filteredData[i]);
            }
        }
    }

    public onChangeSelectedCount(): void {
        const numSelected: number = this.selection.selected.length;
        this.outputSelectedCount.emit(numSelected);
    }

    public validateCpfOrCnpj(personModel: PersonModel): string {
        if (!ObjectUtil.isNull(personModel)) {
            if (!ObjectUtil.isNull(personModel.legalPerson) && !StringUtil.isEmpty(personModel.legalPerson.cnpj)) {
                return StringUtil.format(personModel.legalPerson.cnpj, '00.000.000/0000-00');
            } else if (!ObjectUtil.isNull(personModel.naturalPerson) && !StringUtil.isEmpty(personModel.naturalPerson.cpf)) {
                return StringUtil.format(personModel.naturalPerson.cpf, '000.000.000-00');
            }
        }

        return '';
    }

    public getLabelStatus(status: string): string {
        switch (status) {
            case 'UPDATED': return 'Atualizado';
            case 'NOTUPDATED': return 'Desatualizado';
            case 'UPDATE': return 'A atualizar';
            case 'RELEASE': return 'A liberar';
            case 'NOTERMINAL': return 'Sem terminal';
            case 'NOAPPLICATION': return 'Sem aplicativos';
            case 'TOPVERSION': return 'Versão superior';
            default: return 'Não identificado';
        }
    }

    public getTypeStatus(status: string): LozengeType {
        switch (status) {
            case 'UPDATED': return 'success';
            case 'NOTUPDATED': return 'error';
            case 'UPDATE': return 'information';
            case 'RELEASE': return 'warning';
            case 'NOTERMINAL': return 'disabled';
            case 'NOAPPLICATION': return 'new';
            case 'TOPVERSION': return 'advance';
            default: return 'default';
        }
    }

    public getTooltipStatus(status: string): string {
        switch (status) {
            case 'UPDATED': return 'Cliente atualizado';
            case 'NOTUPDATED': return 'Cliente desatualizado';
            case 'UPDATE': return 'Cliente já liberado, porém não atualizado';
            case 'RELEASE': return 'Cliente com versão igual a atual, porém não liberado';
            case 'NOTERMINAL': return 'Cliente sem terminal cadastrado';
            case 'NOAPPLICATION': return 'Cliente sem aplicativo cadastrado';
            case 'TOPVERSION': return 'Cliente com versão superior a atual';
            default: return 'Não identificado';
        }
    }

    public loadStatus(updateStatus: string): SimpleStatusCell {
        let color: COLOR;
        let text: string;
        switch (updateStatus) {
            // Atualizado
            case 'UPDATED': {
                color = COLOR.green;
                text = 'Atualizado';
                break;
            }
            // Desatualizado
            case 'NOTUPDATED': {
                color = COLOR.red;
                text = 'Desatualizado';
                break;
            }
            // A atualizar
            case 'UPDATE': {
                color = COLOR.blue;
                text = 'A atualizar';
                break;
            }
            // A liberar
            case 'RELEASE': {
                color = COLOR.orange;
                text = 'A liberar';
                break;
            }
            // Sem terminal
            case 'NOTERMINAL': {
                color = COLOR.gray;
                text = 'Sem terminal';
                break;
            }
            // Sem applicativos
            case 'NOAPPLICATION': {
                color = COLOR.purple;
                text = 'Sem aplicativos';
                break;
            }
            // Versão Superior
            case 'TOPVERSION': {
                color = COLOR.darkGreen;
                text = 'Versão superior';
                break;
            }
        }

        return { color, text };
    }

    public loadTooltip(updateStatus: string): string {
        let lReturn: string = '';
        switch (updateStatus) {
            // Atualizado
            case 'UPDATED': {
                lReturn = 'Cliente atualizado';
                break;
            }
            // Desatualizado
            case 'NOTUPDATED': {
                lReturn = 'Cliente desatualizado';
                break;
            }
            // A atualizar
            case 'UPDATE': {
                lReturn = 'Cliente já liberado, porém não atualizado';
                break;
            }
            // A liberar
            case 'RELEASE': {
                lReturn = 'Cliente com versão igual a atual, porém não liberado';
                break;
            }
            // Sem terminal
            case 'NOTERMINAL': {
                lReturn = 'Cliente sem terminal cadastrado';
                break;
            }
            // Sem applicativos
            case 'NOAPPLICATION': {
                lReturn = 'Cliente sem aplicativo cadastrado';
                break;
            }
            // Versão Superior
            case 'TOPVERSION': {
                lReturn = 'Cliente com versão superior a atual';
                break;
            }
        }

        return lReturn;

    }

    public validateButtonReleaseVersion(updateStatus: string): boolean {
        if ((updateStatus !== 'NOTERMINAL') && (updateStatus !== 'NOAPPLICATION')) {
            return ((this.validateAccess('ROLE_SUPPORT') || this.validateAccess('ROLE_DEVELOPERS')) && (this.selection?.selected?.length <= 0));
        }
        return false;
    }

    public validateIconButtonReleaseVersion(clientModel: ClientModel): boolean {
        // return false; = Cancelar atualização;
        // return true; = Liberar atualização;
        const lUpdateStatus: string = clientModel.updateStatus;
        const lListTerminals: TerminalModel[] = clientModel.terminals;

        if ((lUpdateStatus !== 'NOTERMINAL') && (lUpdateStatus !== 'NOAPPLICATION')) {
            if ((lUpdateStatus === 'UPDATED') || (lUpdateStatus === 'UPDATE')) {
                return false;
            } else if (lUpdateStatus === 'RELEASE') {
                return true;
            } else if (lUpdateStatus === 'NOTUPDATED') {
                return true;
            }
            else if (lUpdateStatus === 'TOPVERSION') {
                for (let lCount: number = 0; lCount < lListTerminals.length; lCount++) {
                    const terminal: TerminalModel = lListTerminals[lCount];

                    if (terminal.updateStatus === EnumUpdateStatus.TOPVERSION) {
                        return false;
                    } else {
                        return this.validateTerminalApp(terminal.apps);
                    }

                }
                return false;
            }
        }
        return false;
    }

    private validateTerminalApp(appModel: TerminalAppModel[]): boolean {
        for (let lCount: number = 0; lCount < appModel.length; lCount++) {
            const app: TerminalAppModel = appModel[lCount];

            if ((app.updateStatus === EnumUpdateStatus.UPDATED) || (app.updateStatus === EnumUpdateStatus.UPDATE)) {
                return false;
            } else if ((app.updateStatus === EnumUpdateStatus.NOTUPDATED) || (app.updateStatus === EnumUpdateStatus.RELEASE)) {
                return true;
            }
        }
    }

    private async findTerminalsClient(): Promise<TerminalModel[]> {
        const terminalList: TerminalModel[] = await this._terminalsService.findByCnpjClient(this.cpfOrCnpjClient);
        terminalList.sort((a, b) => {
            if (a.name > b.name) { return 1; }
            if (a.name < b.name) { return -1; }
            return 0;
        });

        return Promise.resolve(terminalList);
    }

    public async onClickTerminal(clientModel: ClientModel): Promise<void> {
        if (ObjectUtil.isNull(this.expandedElement)) {
            this.loadingTerminal = false;
            return;
        }

        this.loadingTerminal = true;

        setTimeout(async () => {
            if (this.validateAccess('ROLE_SUPPORT') || this.validateAccess('ROLE_DEVELOPERS')) {
                if (!ObjectUtil.isNull(clientModel.person)) {
                    let lCpfOrCnpj: string = '';
                    if (!ObjectUtil.isNull(clientModel.person)) {
                        if (!ObjectUtil.isNull(clientModel.person.legalPerson) && !StringUtil.isEmpty(clientModel.person.legalPerson.cnpj)) {
                            lCpfOrCnpj = clientModel.person.legalPerson.cnpj;
                        } else if (!ObjectUtil.isNull(clientModel.person.naturalPerson) && !StringUtil.isEmpty(clientModel.person.naturalPerson.cpf)) {
                            lCpfOrCnpj = clientModel.person.naturalPerson.cpf;
                        }
                    }

                    if ((StringUtil.isEmpty(lCpfOrCnpj)) || ((this.expand) && (lCpfOrCnpj === this.cpfOrCnpjClient))) {
                        this.loadingTerminal = false;
                        return;
                    }

                    if (!StringUtil.isEmpty(lCpfOrCnpj) && (lCpfOrCnpj !== this.cpfOrCnpjClient)) {
                        this.cpfOrCnpjClient = lCpfOrCnpj; // Tem que ficar acima do this.findTerminalsClient();
                        this.terminalList = [];
                        this.terminalList = await this.findTerminalsClient();
                        this.loadingTerminal = false;
                    }
                } else {
                    this.loadingTerminal = false;
                }
            } else {
                this.loadingTerminal = false;
            }
        });
    }

    private openDialogConfirm(typeDialog: TYPE_DIALOG, message: string, buttons: TYPE_BUTTONS): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this._dialogService.open(DialogConfirmComponent, {
                width: '25%',
                data: { type: typeDialog, message: message, buttons: buttons }
            });

            dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            }, (error) => {
                resolve(false);
            });
        });
    }

    public async onClickActionClients(clientModel: ClientModel = null, updateStatus: string = '', action: string = ''): Promise<void> {
        this.expand = false;
        let lListClientsModel: ClientModel[] = [];
        let lIdClient: number = -1;
        if (this.selection.selected.length > 0) {
            lListClientsModel = this.selection.selected.slice();

            // Remove todos os clientes com status igual à NOTERMINAL || NOAPPLICATION || TOPVERSION. Motivo: Os NOTERMINAL e
            // NOAPPLICATION devem ser corrigidos primeiramente. Já os TOPVERSION podem ter versões liberadas ou canceladas de acordo
            // com o status dos terminais ou dos aplicativos.
            for (let lCount: number = 0; lCount < lListClientsModel.length; lCount++) {
                if ((lListClientsModel[lCount].updateStatus === 'NOTERMINAL') ||
                    (lListClientsModel[lCount].updateStatus === 'NOAPPLICATION') ||
                    (lListClientsModel[lCount].updateStatus === 'TOPVERSION')) {
                    lListClientsModel.splice(lCount, 1);
                }
            }

        } else {
            lListClientsModel.push(clientModel);
            lIdClient = clientModel.id;
        }

        if (lListClientsModel.length > 0) {
            if (action) {
                switch (action) {
                    case 'RELEASE_VERSION': { // Liberar Atualização
                        const textMessage = lListClientsModel.length > 1 ? 'Deseja realmente liberar a atualização para os clientes selecionados?' : `Deseja realmente liberar a atualização para ${lListClientsModel[0].person.name}?`;
                        this.openDialogConfirm(TYPE_DIALOG.CONFIRMATION, textMessage, TYPE_BUTTONS.SI_NO).then((resultConfirm) => {
                            if (resultConfirm) {
                                this._updateClientService.releaseVersionClient(lListClientsModel.map(c => c.id)).then((result) => {
                                    if (result) {
                                        this.refreshClients((lIdClient < 0), lIdClient);
                                    }
                                });
                            }
                        });
                        break;
                    }
                    case 'PREVIOUS_VERSION': { // Cancela Atualização
                        const textMessage = lListClientsModel.length > 1 ? 'Deseja realmente cancelar a atualização dos clientes selecionados?' : `Deseja realmente cancelar a atualização do ${lListClientsModel[0].person.name}?`;
                        this.openDialogConfirm(TYPE_DIALOG.CONFIRMATION, textMessage, TYPE_BUTTONS.SI_NO).then((resultConfirm) => {
                            if (resultConfirm) {
                                this._updateClientService.cancelVersionClient(lListClientsModel.map(c => c.id)).then((result) => {
                                    if (result) {
                                        this.refreshClients((lIdClient < 0), lIdClient);
                                    }
                                });
                            }
                        });
                        break;
                    }
                    case 'RELEASE_CONTINGENCY': { // Liberar Contingência
                        await this.releaseContingency(clientModel, lListClientsModel).then((result) => {
                            if (result) {
                                this.refreshClients((lIdClient < 0), lIdClient);
                            }
                        });
                        break;
                    }
                }
            } else {
                if ((updateStatus === 'UPDATED') || (updateStatus === 'UPDATE') || (!this.validateIconButtonReleaseVersion(clientModel))) {
                    this.openDialogConfirm(TYPE_DIALOG.CONFIRMATION, `Deseja realmente cancelar a atualização do ${clientModel.person.name}?`, TYPE_BUTTONS.SI_NO).then((resultConfirm) => {
                        if (resultConfirm) {
                            this._updateClientService.cancelVersionClient(lListClientsModel.map(c => c.id)).then((result) => {
                                if (result) {
                                    this.refreshClients((lIdClient < 0), lIdClient);
                                }
                            });
                        }
                    });
                } else if ((updateStatus === 'NOTUPDATED') || (updateStatus === 'RELEASE') || (this.validateIconButtonReleaseVersion(clientModel))) {
                    this.openDialogConfirm(TYPE_DIALOG.CONFIRMATION, `Deseja realmente liberar a atualização para ${clientModel.person.name}?`, TYPE_BUTTONS.SI_NO).then((resultConfirm) => {
                        if (resultConfirm) {
                            this._updateClientService.releaseVersionClient(lListClientsModel.map(c => c.id)).then((result) => {
                                if (result) {
                                    this.refreshClients((lIdClient < 0), lIdClient);
                                }
                            });
                        }
                    });
                }
            }

            this.selectDesiredQuantity(0);
            this.onChangeSelectedCount();
            document.getElementById('inputFindClients').focus();
        }
    }

    private releaseContingency(clientModel: ClientModel = null, listClients: ClientModel[] = []): Promise<boolean> {
        return new Promise((resolve) => {
            const lListContingencyBlocked: ContingencyBlockedModel[] = [];
            let lNameClient: string = null;
            let lCnpjOrCpf: string = null;

            if (clientModel !== null) {
                const lContingencyBlockedModel: ContingencyBlockedModel = {
                    clientId: null,
                    dateBlockingContingency: null,
                };

                lNameClient = clientModel.person.name;
                lCnpjOrCpf = (clientModel.person.legalPerson ? clientModel.person.legalPerson.cnpj : clientModel.person.naturalPerson.cpf);
                lContingencyBlockedModel.clientId = clientModel.id;

                lListContingencyBlocked.push(lContingencyBlockedModel);
            } else if (listClients !== null) {
                for (let lCount = 0; lCount < listClients.length; lCount++) {
                    const lContingencyBlockedModel: ContingencyBlockedModel = {
                        clientId: null,
                        dateBlockingContingency: null,
                    };

                    lContingencyBlockedModel.clientId = listClients[lCount].id;
                    lListContingencyBlocked.push(lContingencyBlockedModel);
                }
            }

            const clientContingencyBlockedData: ClientContingencyBlockedModel = {
                cnpjOrCpf: lCnpjOrCpf,
                nameClient: lNameClient,
                listContingencyBlocked: lListContingencyBlocked,
            };

            const dialogRef: MatDialogRef<DialogContingencyBlockedComponent> = this._dialogService.open(DialogContingencyBlockedComponent, {
                width: '600px',
                data: clientContingencyBlockedData
            });

            dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    public async onClickDelayBlocking(clientModel: ClientModel): Promise<void> {
        this.expand = false;
        this.outputLoading.emit(true);

        const cnpjOrCpfClient: string = (clientModel.person.legalPerson ? clientModel.person.legalPerson.cnpj : clientModel.person.naturalPerson.cpf);
        if (this._authenticationService.getUserRolesByAuthorities() === 'ROLE_SUPPORT') {
            const isPermittedNewDelay: boolean = await this._delayBlockingService.verifyIfPermittedDelayBlockingSupport(cnpjOrCpfClient);
            if (!isPermittedNewDelay) {
                const message: string = `Não é possível realizar a operação, o cliente possuí uma prorrogação ativa ou já teve sua licença prorrogada este mês. Verifique com o departamento financeiro.`;
                await this.openDialogConfirm(TYPE_DIALOG.INFORMATION, message, TYPE_BUTTONS.OK);
                this.outputLoading.emit(false);
                return;
            }
        } else {
            const isPermittedNewDelay: boolean = await this._delayBlockingService.verifyIfExistDelayActive(cnpjOrCpfClient);
            if (!isPermittedNewDelay) {
                const message: string = `O cliente já possuí uma prorrogação ativa, portanto não será possível criar uma prorrogação.`;
                await this.openDialogConfirm(TYPE_DIALOG.INFORMATION, message, TYPE_BUTTONS.OK);
            }
        }

        const dialogRef: MatDialogRef<DelayBlockingListDialogComponent> = this._dialogService.open(DelayBlockingListDialogComponent, {
            width: '55%',
            data: clientModel
        });

        await dialogRef.afterClosed().toPromise().then((result) => {
            if (result) {
                this.refreshClients(false, clientModel.id).then(() => {
                    document.getElementById('inputFindClients').focus();
                });
            }
        });
        this.outputLoading.emit(false);
    }

    public onClickUpdateBlockedContingency(clientModel: ClientModel): void {
        this.expand = false;
        this.onClickActionClients(clientModel, '', 'RELEASE_CONTINGENCY');
    }

    public onClickLicenseGenerator(clientModel: ClientModel): void {
        this.expand = false;
        const dialogRef: MatDialogRef<DialogLicenseGeneratorComponent> = this._dialogService.open(DialogLicenseGeneratorComponent, {
            width: '600px',
            data: { client: clientModel }
        });

        dialogRef.afterClosed().subscribe((result) => {
            document.getElementById('inputFindClients').focus();
        });
    }

    public expandElement(clientModel: ClientModel): void {
        if (this.expand) {
            this.expandedElement = this.expandedElement === clientModel ? null : clientModel;
        }
        this.expand = true;
    }

    public sortData(sort: Sort): void {
        if (!this.dataSource.data) {
            return;
        }

        this.expandedElement = null;
        this.cpfOrCnpjClient = null;

        const entityData: ClientModel[] = this.dataSource.data.slice();
        if ((!sort.active) || (!sort.direction)) {
            this.dataSource.data = this._dataSourceInitial.slice();
            return;
        }

        this.dataSource.data = entityData.sort((a, b) => {
            const isAsk: boolean = sort.direction === 'asc';

            switch (sort.active) {
                case 'corporateName': {
                    const lFieldA: string | number = (a.person.legalPerson ? a.person.name : a.person.fantasyName);
                    const lFieldB: string | number = (b.person.legalPerson ? b.person.name : b.person.fantasyName);
                    return Library.compareSort(lFieldA, lFieldB, isAsk);
                }
                case 'name': {
                    const lFieldA: string | number = (a.person.name ? a.person.fantasyName : a.person.name);
                    const lFieldB: string | number = (b.person.name ? b.person.fantasyName : b.person.name);
                    return Library.compareSort(lFieldA, lFieldB, isAsk);
                }
                case 'cpfOrCnpj': {
                    const lFieldA: string | number = (a.person.legalPerson ? a.person.legalPerson.cnpj : a.person.naturalPerson.cpf);
                    const lFieldB: string | number = (b.person.legalPerson ? b.person.legalPerson.cnpj : b.person.naturalPerson.cpf);
                    return Library.compareSort(lFieldA, lFieldB, isAsk);
                }
                case 'blocked': {
                    const lFieldA: string | number = (a.blocked ? 0 : 1);
                    const lFieldB: string | number = (b.blocked ? 0 : 1);
                    return Library.compareSort(lFieldA, lFieldB, isAsk);
                }
                case 'quantityNfDenied': {
                    const lFieldA: string | number = (a.quantityNfDenied ? a.quantityNfDenied : null);
                    const lFieldB: string | number = (b.quantityNfDenied ? b.quantityNfDenied : null);
                    return Library.compareSort(lFieldA, lFieldB, isAsk);
                }
                case 'dateBlockingContingency': {
                    const lFieldA: Date = (a.dateBlockingContingency ? a.dateBlockingContingency : null);
                    const lFieldB: Date = (b.dateBlockingContingency ? b.dateBlockingContingency : null);
                    return Library.compareSortDate(lFieldA, lFieldB, isAsk);
                }
                case 'dateTimeLastAccess': {
                    const lFieldA: Date = (a.dateTimeLastAccess ? a.dateTimeLastAccess : null);
                    const lFieldB: Date = (b.dateTimeLastAccess ? b.dateTimeLastAccess : null);
                    return Library.compareSortDate(lFieldA, lFieldB, isAsk);
                }
            }
        });
    }

    public async migrateClientToWeb(clientModel: ClientModel): Promise<void> {
        this.expand = false;

        if (!ObjectUtil.isNull(clientModel.person)) {
            const isConfirmed: boolean = await this.openDialogConfirm(TYPE_DIALOG.CONFIRMATION, `Deseja realmente migrar o ${clientModel.person.name} para o sistema web?`, TYPE_BUTTONS.SI_NO);
            if (isConfirmed) {
                await this._updateClientService.updateHasFinancial(clientModel.id, !clientModel.hasFinancial).toPromise().then(result => {
                    if (result) {
                        this.refreshClients(false, clientModel.id);
                    }
                }, error => {
                    this._snackbarService.showMessage('Ocorreu um erro ao migrar o cliente. Motivo: ' + error, true);
                })
            }
        }
    }

    private onClickExportExcelClients(): void {
        if (!ObjectUtil.isNull(this.dataSource) && (!ArrayUtil.isEmpty(this.dataSource.data) || !ArrayUtil.isEmpty(this.dataSource.filteredData))) {
            this.isLoading = true;

            const headerExcel: HeaderExcel[] = [
                { name: 'Razão Social', property: 'name', },
                { name: 'Nome fantasia', property: 'fantasyName' },
                { name: 'CNPJ / CPF', property: 'cnpjOrCpf' },
                { name: 'Bloqueado', property: 'blocked' },
                { name: 'Bloq. Contingência', property: 'dateBlockingContingency' },
                { name: 'Último acesso', property: 'dateTimeLastAccess' },
                { name: 'Status', property: 'status' },
                { name: 'Migrado', property: 'hasFinancial', },
            ];

            const bodyExcelList: ClientExcelModelDTO[] = [];

            for (let i: number = 0; i < this.dataSource.filteredData.length; i++) {
                const clientModel: ClientModel = this.dataSource.filteredData[i];
                const clientExcelModel: ClientExcelModelDTO = {
                    name: clientModel.person.name,
                    fantasyName: clientModel.person.fantasyName,
                    cnpjOrCpf: !ObjectUtil.isNull(clientModel.person.legalPerson) ? StringUtil.format(clientModel.person.legalPerson.cnpj, '00.000.000/0000-00') : StringUtil.format(clientModel.person.naturalPerson.cpf, '000.000.000-00'),
                    blocked: clientModel.blocked ? 'SIM' : 'NÃO',
                    dateBlockingContingency: DateUtil.format(clientModel.dateBlockingContingency, DateUtil.DATE_FORMAT),
                    dateTimeLastAccess: DateUtil.format(clientModel.dateTimeLastAccess, DateUtil.DATE_FORMAT),
                    status: this.loadStatus(clientModel.updateStatus).text,
                    hasFinancial: clientModel.hasFinancial ? 'SIM' : 'NÃO',
                };
                bodyExcelList.push(clientExcelModel);
            }

            this._excelService.exportExcelFile(headerExcel, bodyExcelList, 'clientes');
            this.isLoading = false;
        } else {
            this._snackbarService.showMessage('Não foi possível exportar o relatório pois não há nenhum registro', true);
        }
    }

}
